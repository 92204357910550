
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'


import utc from 'dayjs/plugin/utc'
import relativeTime from 'dayjs/plugin/relativeTime'


dayjs.extend(updateLocale)

dayjs.extend(utc)
dayjs.extend(relativeTime)



// defaultLocale: undefined



export default dayjs
