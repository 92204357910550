import revive_payload_client_Y1iG1M254b from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_IJZ6JGnk8i from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_WkPhc3B9Xm from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_hgl0GEGfmD from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_5LLkunBFSM from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Um4BJQ51l9 from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_N5KBxlPhBS from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dMBHSzOrWI from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.18.1_typescript@5.4.2_vue@3.5.8_typescript@5.4.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_QxWSRzNtuw from "/app/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@20.14.10_eslint@9.16.0_jiti@1.21.6__ioredis@5.4_hdwqfjzrhz5oxb236upcnarnsa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5wpCzAya68 from "/app/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.4_rollup@4.18.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import plugin_e9yVtXNtY8 from "/app/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.5.8_typescript@5.4.2_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_ZOf3UBM5YC from "/app/node_modules/.pnpm/nuxt-echarts@0.1.0_echarts@5.5.1_magicast@0.3.4_rollup@4.18.1/node_modules/nuxt-echarts/dist/runtime/plugin.js";
import plugin_0eqJTh028b from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.8.1_magicast@0.3.4_next-auth@4.21.1_next@13.5.6_@babel+core@7.24.7_@ope_mh3dxmbzetj4add6aof4g7gryy/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_sH4cTGbYvT from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.0.3_@babel+parser@7.25.6_magicast@0.3.4_rollup@4.18.1_vue@3.5.8_typescript@5.4.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.mjs";
import plugin_2KDniskf0v from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.2_magicast@0.3.4_rollup@4.18.1_typesc_xsocm5rfswkahlaro4yl4tmune/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import sentry_client_a4hAB0MOGi from "/app/app/plugins/sentry.client.ts";
export default [
  revive_payload_client_Y1iG1M254b,
  unhead_IJZ6JGnk8i,
  router_WkPhc3B9Xm,
  payload_client_hgl0GEGfmD,
  navigation_repaint_client_5LLkunBFSM,
  check_outdated_build_client_Um4BJQ51l9,
  chunk_reload_client_N5KBxlPhBS,
  plugin_vue3_dMBHSzOrWI,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QxWSRzNtuw,
  plugin_5wpCzAya68,
  plugin_e9yVtXNtY8,
  plugin_ZOf3UBM5YC,
  plugin_0eqJTh028b,
  primevue_plugin_egKpok8Auk,
  plugin_client_sH4cTGbYvT,
  plugin_2KDniskf0v,
  sentry_client_a4hAB0MOGi
]